.CConfigMainContainer {
  width: 95vw;
}

.CConfigCongurationArea {
  display: flex;
  flex-direction: row;
}

.CConfigLeftArea {
  width: 40%;
  margin-right: 24px;
}

.CConfigRightArea {
  width: 60%;
  background-color: #313131;
}

.CConfigButtonArea {
  height: 96px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
}

.CConfigButtonContainer {
  height: 48px;
  display: flex;
}

.CConfigButtonContainerSpinner {
  display: flex;
  justify-content: end;
  padding: 20px 170px 20px 20px;
}
